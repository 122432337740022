import { AxiosError } from 'axios';

import { FormErrors } from '@xemplo/gp-types';

export function getSimpleErrorMessage(error: AxiosError<FormErrors<unknown>>): string {
  const errors = error.response?.data?.errors;
  const allErrorMessages = Object.values(errors ?? {})
    .flat()
    .join(', ')
    .trim();
  const simpleError = error.message;
  return allErrorMessages ? allErrorMessages : simpleError ?? 'An error occurred';
}
