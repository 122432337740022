import styled from 'styled-components';

import { MinBreakpoint } from '@xemplo/style-constants';

import {
  IconCommonStyles,
  IconContainerCommonStyles,
  IconWrapperModeCommonStyles,
} from '../common';

export const StyledIconContainer = styled.div`
  display: none;

  @media ${MinBreakpoint.medium} {
    width: 56px;
    ${IconContainerCommonStyles};
  }
`;

export const StyledHeaderIconWrapper = styled.span`
  display: none;
  flex-shrink: 0;

  + .icon-gradient {
    display: none;
  }

  ${IconWrapperModeCommonStyles};

  @media ${MinBreakpoint.medium} {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    transition: all 0.2s ease-out;
    ${IconCommonStyles};
  }
`;
