import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { parseToCalendarDate, TextDatePickerV2 } from '@xemplo/text-date-picker';

import * as S from '../../modal.styles';

import { RecurringTypeValues } from './recurring-type';

export function RecurringEndDate() {
  const { register, unregister, watch } = useFormContext();
  const watchRecurringType = watch('recurringType');
  const showEndDate = watchRecurringType === RecurringTypeValues.END_DATE;
  useEffect(() => {
    if (showEndDate) {
      register('recurringEndDate', {
        required: 'This field is required',
      });
    } else {
      unregister('recurringEndDate');
    }
  }, [register, unregister, showEndDate]);

  return (
    showEndDate && (
      <S.FormLabel>
        End date
        <TextDatePickerV2
          name="recurringEndDate"
          inputProps={{
            placeholder: 'Select end date',
            width: '100%',
            minValue: parseToCalendarDate(),
          }}
        />
      </S.FormLabel>
    )
  );
}
