import { Amendment, AmendmentStatusIds, ChangeTaskTypes } from '@xemplo/gp-types';

import { EditButtonTypes } from './edit-button.types';

// Common status arrays
const commonValidStatuses = [AmendmentStatusIds.CREATED, AmendmentStatusIds.UPDATED];
const validAmendmentStatuses = [
  ...commonValidStatuses,
  AmendmentStatusIds.SUBMITTED,
  AmendmentStatusIds.REJECTED,
  AmendmentStatusIds.RESUBMITTED,
  AmendmentStatusIds.DRAFT,
];

const EditButtonConfig = {
  [ChangeTaskTypes.TERMINATION]: {
    button: EditButtonTypes.termination,
    isValid: (amendment: Amendment) =>
      [AmendmentStatusIds.CREATED, AmendmentStatusIds.UPDATED].includes(
        amendment.amendmentStatusID
      ),
  },
  [ChangeTaskTypes.PAY_INSTRUCTION]: {
    button: EditButtonTypes.payInstruction,
    isValid: (amendment: Amendment) =>
      validAmendmentStatuses.includes(amendment.amendmentStatusID),
  },
  [ChangeTaskTypes.ALLOWANCE]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) =>
      validAmendmentStatuses.includes(amendment.amendmentStatusID),
  },
  [ChangeTaskTypes.DEDUCTION]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) =>
      validAmendmentStatuses.includes(amendment.amendmentStatusID),
  },
  [ChangeTaskTypes.SALARY]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) =>
      validAmendmentStatuses.includes(amendment.amendmentStatusID),
  },
};

export const getEditButtonType = (amendment: Amendment) => {
  const config = EditButtonConfig[amendment.amendmentTaskTypeID];
  if (config?.isValid(amendment)) {
    return config.button;
  }

  return null;
};
